import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import "./Profile.css";
import {
  getToken,
  removeToken,
  storeToken,
} from "../../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import Swal from "sweetalert2";
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { Input } from "antd";

export default function UserProfile() {
  const navigate = useNavigate();
  const uploadIconStyle = {
    position: "absolute",
    top: "90%",
    left: "50%",
    transform: "translate(20%, -68%)",
    background: "#007bff",
    borderRadius: "50%",
    color: "#fff",
    padding: "10px",
    cursor: "pointer",
    zIndex: "1", // Ensures the icon is above the image
  };

  const fileInputStyle = {
    display: "none", // Hide the file input
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    // You can now do something with the selected file, such as uploading it to a server or displaying a preview
    console.log("Selected file:", file);
  };

  const itemsPerPage = 6; // Number of items to display per page
  const [userbooking, setUserBooking] = useState({
    date: "",
    from: "",
    to: "",
    payment: "",
    status: "",
  });
  const [userDetails, setUserDetails] = useState([]);
  const [editProfileToggle, setEditProfileToggle] = useState(false);

  const [editedUserDetails, setEditedUserDetails] = useState({});

  const handleDownload = () => {
    const id = "your_id"; // Replace with the actual ID
    const downloadLink = `https://www.backend.carsinafrica.com/${id}`;

    window.location.href = downloadLink;
  };

  const userLoggedData = getToken();

  const handleLogout = async () => {
    removeToken(userLoggedData);
    window.location.href = "/user-login";
  };

  const handleEdit = async () => {
    setEditProfileToggle(true);
  };

  const handleSubmitEdit = async () => {
    // e.handlePreventDefault();

    if (
      editedUserDetails.name === "" ||
      editedUserDetails.email === "" ||
      editedUserDetails.contact_number === "" ||
      editedUserDetails.address === ""
    ) {
      Swal.fire({
        title: "Error",
        text: "Please enter your profile data.",
        icon: "error",
      });
    } else {
      axios
        .put(
          `https://backend.carsinafrica.com/frontend/update-profile/${userLoggedData.user_id}`,
          editedUserDetails
        )
        .then((response) => {
          if (response.data.status === "Failed") {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          } else {
            storeToken(response.data.userinfo);
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/profile`;
            });
          }
        });
    }
  };

  const handleCancelEdit = async () => {
    setEditProfileToggle(false);
  };

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const textPattern = /^[a-zA-Z\s-]*$/;

    if (name === "name") {
      if (textPattern.test(value)) {
        setEditedUserDetails((values) => ({ ...values, [name]: value }));
      }
    } else {
      setEditedUserDetails((values) => ({ ...values, [name]: value }));
    }
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event;

    // Update editedUserDetails with the raw phone number string
    setEditedUserDetails((values) => ({
      ...values,
      contact_number: phoneNumber,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (value.length > 15) {
      const truncatedValue = value.substring(0, 15);
      setEditedUserDetails((prevState) => ({
        ...prevState,
        [name]: truncatedValue,
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-details?id=${userLoggedData.user_id}`,
          {
            responseType: "json",
          }
        );
        setUserDetails(response.data.data);
        setEditedUserDetails({
          name: response.data.data.name,
          email: response.data.data.email,
          contact_number: response.data.data.contact_number,
          address: response.data.data.address,
          gender: response.data.data.gender,
        });
      } catch (error) {
        console.error(error);
        navigate("/");
        window.location.reload(); // Reload the page
      }
    };
    fetchData();
  }, []);
  const [activeTab, setActiveTab] = useState("personal");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    handleCancelEdit();
  };
  const activeClass = "active-tab";

  if (editProfileToggle === true) {
    return (
      <>
        <Header />
        <section className="banner-section">
          <img
            src={`${process.env.PUBLIC_URL}/images/mini-coupe-high-speed-drive-road-with-front-lights-profile.jpg`}
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-left align-items-center text-center">
              <div className="col-md-12">
                <h1 className="banner-caption text-white">Your Profile</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 header">
          <div className="container full-width">
            <div className="row">
              <div className="col-md-3">
                {/* Tabs nav */}
                <div
                  className="nav flex-column nav-pills nav-pills-custom"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "personal" ? activeClass : ""
                    }`}
                    onClick={() => handleTabClick("personal")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22.5 17.25a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0m1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0m-7.5-3v6a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0M14.25 18h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 0 0 0 1.5M1.5 17.25a6.003 6.003 0 0 1 8.356-5.518.75.75 0 0 0 .588-1.38A7.504 7.504 0 0 0 0 17.25a.75.75 0 0 0 1.5 0m9.375-12.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m1.5 0a4.875 4.875 0 1 0-9.75 0 4.875 4.875 0 0 0 9.75 0"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Personal Details
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left ${
                      activeTab === "bookings" ? activeClass : ""
                    }`}
                    onClick={() => handleTabClick("bookings")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M.75 4.5h16.34a3.5 3.5 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5M20.5 1.75a2 2 0 1 1-2 2 2 2 0 0 1 2-2m2.75 17.75H9.46a3.5 3.5 0 0 0-6.83 0H.75a.75.75 0 0 0 0 1.5h1.88a3.5 3.5 0 0 0 6.83 0h13.79a.75.75 0 0 0 0-1.5m-17.2 2.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2M23.25 11h-7.84a3.49 3.49 0 0 0-6.82 0H.75a.75.75 0 0 0 0 1.5h7.84a3.49 3.49 0 0 0 6.82 0h7.84a.75.75 0 0 0 0-1.5M12 13.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Preferences
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left ${
                      activeTab === "reviews" ? activeClass : ""
                    } custom-border-bottom`}
                    onClick={() => handleTabClick("reviews")}
                    role="tab"
                    style={{ borderBottom: "1px solid #e3e3e3" }}
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19.5 16.5v5.25a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75zm1.5 0v-5.25A2.25 2.25 0 0 0 18.75 9H5.25A2.25 2.25 0 0 0 3 11.25v10.5A2.25 2.25 0 0 0 5.25 24h13.5A2.25 2.25 0 0 0 21 21.75zM7.5 9.75V6a4.5 4.5 0 0 1 9 0v3.75a.75.75 0 0 0 1.5 0V6A6 6 0 0 0 6 6v3.75a.75.75 0 0 0 1.5 0M12 15a1.125 1.125 0 1 0 .004 0h-.006a.75.75 0 0 0 .004 1.5H12a.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Security
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-md-9">
                {/* Tabs content */}
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade rounded bg-white profile-top-margin ${
                      activeTab === "personal" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">
                          Edit Your Personal Details
                        </h2>
                        <p className="light-font-weight">
                          Update your information and find out how it's used.
                        </p>
                      </div>
                    </div>
                    <div className="personal-info">
                      <div className="row personal-info__item personal-details-btop">
                        <div className="col-md-2">
                          <p className="">Name</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            size="large"
                            placeholder="Enter Your Name"
                            className="custom-input w-100"
                            value={editedUserDetails.name}
                            onChange={handleUserLoginDetails}
                            required
                          />
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Display Name</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            size="large"
                            placeholder="Enter Your Name"
                            className="custom-input w-100"
                            value={editedUserDetails.name}
                            onChange={handleUserLoginDetails}
                          />
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Email Address</p>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            size="large"
                            placeholder="Enter Your Email"
                            className="custom-input w-100"
                            value={editedUserDetails.email}
                            onChange={handleUserLoginDetails}
                            required
                          />
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Phone Number</p>
                        </div>
                        <div className="col-md-6">
                          <PhoneInput
                            defaultCountry="IN"
                            country="IN"
                            type="text"
                            id="contact_number"
                            name="contact_number"
                            className="phoneinputfield"
                            placeholder="Mobile Number"
                            value={editedUserDetails.contact_number}
                            onChange={handlePhoneNumberChange}
                            onBlur={handleBlur}
                            style={{ height: "54px" }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Gender</p>
                        </div>
                        <div className="col-md-2">
                          <div className="form-check custom-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="male"
                              value="1"
                              checked={editedUserDetails.gender === "1"}
                              onChange={handleUserLoginDetails}
                            />
                            <label
                              className="form-check-label custom-gender male-gender"
                              htmlFor="male"
                            >
                              Male
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-check custom-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="female"
                              value="0"
                              checked={editedUserDetails.gender === "0"}
                              onChange={handleUserLoginDetails}
                            />
                            <label
                              className="form-check-label custom-gender female-gender"
                              htmlFor="female"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-check custom-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="other"
                              value="2"
                              checked={editedUserDetails.gender === "2"}
                              onChange={handleUserLoginDetails}
                            />
                            <label
                              className="form-check-label custom-gender female-gender"
                              htmlFor="other"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Address</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="address"
                            name="address"
                            size="large"
                            placeholder="Enter Your Address"
                            className="custom-input w-100"
                            value={editedUserDetails.address}
                            onChange={handleUserLoginDetails}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column justify-content-center">
                      <button
                        className="btn btn-success m-2"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-success m-2"
                        onClick={handleSubmitEdit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade  rounded bg-white profile-top-margin ${
                      activeTab === "bookings" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">Preferences</h2>
                        <p className="light-font-weight">
                          Change your language, currency and accessibility
                          requirements.
                        </p>
                      </div>
                    </div>
                    <p></p>
                    <div className="row personal-info__item personal-details-btop">
                      <div className="col-md-2">
                        <p className="">Currency</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">Dollar ($)</p>
                      </div>
                    </div>
                    <div className="row personal-info__item ">
                      <div className="col-md-2">
                        <p className="">Language</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">English</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  rounded bg-white profile-top-margin ${
                      activeTab === "reviews" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">Security</h2>
                        <p className="light-font-weight">
                          Change your security settings, set up secure
                          authentication or delete your account.
                        </p>
                      </div>
                    </div>
                    <p></p>
                    <div className="row personal-info__item personal-details-btop">
                      <div className="col-md-2">
                        <p className="">Password</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">Change your password</p>
                      </div>
                      <div className="col-md-4 edit-button">
                        <a href="/change-password">
                          <button className="btn btn-link profile-edit">
                            Change
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <section className="banner-section">
          <img
            src={`${process.env.PUBLIC_URL}/images/mini-coupe-high-speed-drive-road-with-front-lights-profile.jpg`}
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-left align-items-center text-center">
              <div className="col-md-12">
                <h1 className="banner-caption text-white">Your Profile</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 header">
          <div className="container full-width">
            <div className="row">
              <div className="col-md-3">
                {/* Tabs nav */}
                <div
                  className="nav flex-column nav-pills nav-pills-custom"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "personal" ? activeClass : ""
                    }`}
                    onClick={() => handleTabClick("personal")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22.5 17.25a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0m1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0m-7.5-3v6a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0M14.25 18h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 0 0 0 1.5M1.5 17.25a6.003 6.003 0 0 1 8.356-5.518.75.75 0 0 0 .588-1.38A7.504 7.504 0 0 0 0 17.25a.75.75 0 0 0 1.5 0m9.375-12.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m1.5 0a4.875 4.875 0 1 0-9.75 0 4.875 4.875 0 0 0 9.75 0"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Personal Details
                    </span>
                  </a>

                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "bookings" ? activeClass : ""
                    }`}
                    onClick={() => handleTabClick("bookings")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M.75 4.5h16.34a3.5 3.5 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5M20.5 1.75a2 2 0 1 1-2 2 2 2 0 0 1 2-2m2.75 17.75H9.46a3.5 3.5 0 0 0-6.83 0H.75a.75.75 0 0 0 0 1.5h1.88a3.5 3.5 0 0 0 6.83 0h13.79a.75.75 0 0 0 0-1.5m-17.2 2.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2M23.25 11h-7.84a3.49 3.49 0 0 0-6.82 0H.75a.75.75 0 0 0 0 1.5h7.84a3.49 3.49 0 0 0 6.82 0h7.84a.75.75 0 0 0 0-1.5M12 13.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Preferences
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "reviews" ? activeClass : ""
                    } custom-border-bottom`}
                    onClick={() => handleTabClick("reviews")}
                    role="tab"
                    style={{ borderBottom: "1px solid #e3e3e3" }}
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19.5 16.5v5.25a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75zm1.5 0v-5.25A2.25 2.25 0 0 0 18.75 9H5.25A2.25 2.25 0 0 0 3 11.25v10.5A2.25 2.25 0 0 0 5.25 24h13.5A2.25 2.25 0 0 0 21 21.75zM7.5 9.75V6a4.5 4.5 0 0 1 9 0v3.75a.75.75 0 0 0 1.5 0V6A6 6 0 0 0 6 6v3.75a.75.75 0 0 0 1.5 0M12 15a1.125 1.125 0 1 0 .004 0h-.006a.75.75 0 0 0 .004 1.5H12a.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Security
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-md-9">
                {/* Tabs content */}
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade rounded bg-white profile-top-margin ${
                      activeTab === "personal" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">
                          Personal Details
                        </h2>
                        <p className="light-font-weight">
                          Update your information and find out how it's used.
                        </p>
                      </div>
                      {/* <div className="col-md-1">
                        <i className="bi bi-person-circle profile-icon"></i>
                      </div> */}
                    </div>
                    <div className="personal-info">
                      <div className="row personal-info__item personal-details-btop">
                        <div className="col-md-2">
                          <p className="">Name</p>
                        </div>
                        <div className="col-md-6">
                          <p className="">{userDetails.name}</p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Display Name</p>
                        </div>
                        <div className="col-md-6">
                          <p className="">{userDetails.name}</p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Email Address</p>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <p className="">
                            {userDetails.email}
                            <span className="badge bg-success ms-2 ">
                              Verified
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Phone Number</p>
                        </div>
                        <div className="col-md-6">
                          <p className="">{userDetails.contact_number}</p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      {/* <div className="row personal-info__item">
                                                <div className="col-md-2">
                                                    <p className="">Date of birth</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="">26th April 1997</p>
                                                </div>
                                                <div className="col-md-4 edit-button">
                                                    <button className="btn btn-link profile-edit">Edit</button>
                                                </div>
                                            </div>
                                            <div className="row personal-info__item">
                                                <div className="col-md-2">
                                                    <p className="">Nationality</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="">Indian</p>
                                                </div>
                                                <div className="col-md-4 edit-button">
                                                    <button className="btn btn-link profile-edit">Edit</button>
                                                </div>
                                            </div> */}
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Gender</p>
                        </div>
                        <div className="col-md-6">
                          <p className="">
                            {userDetails.gender === "1"
                              ? "Male"
                              : userDetails.gender === "0"
                              ? "Female"
                              : "Other"}
                          </p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="row personal-info__item">
                        <div className="col-md-2">
                          <p className="">Address</p>
                        </div>
                        <div className="col-md-6">
                          <p className="">{userDetails.address}</p>
                        </div>
                        <div className="col-md-4 edit-button">
                          <button
                            className="btn btn-link profile-edit"
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      {/* <div className="row personal-info__item">
                                                <div className="col-md-2">
                                                    <p className="">Passport details </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="">Not provided</p>
                                                </div>
                                                <div className="col-md-4 edit-button">
                                                    <button className="btn btn-link profile-edit">Edit</button>
                                                </div>
                                            </div> */}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade  rounded bg-white profile-top-margin ${
                      activeTab === "bookings" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">Preferences</h2>
                        <p className="light-font-weight">
                          Change your language, currency and accessibility
                          requirements.
                        </p>
                      </div>
                    </div>
                    <p></p>
                    <div className="row personal-info__item personal-details-btop">
                      <div className="col-md-2">
                        <p className="">Currency</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">Dollar ($)</p>
                      </div>
                      {/* <div className="col-md-4 edit-button">
                                                <button className="btn btn-link profile-edit">Edit</button>
                                            </div> */}
                    </div>
                    <div className="row personal-info__item ">
                      <div className="col-md-2">
                        <p className="">Language</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">English</p>
                      </div>
                      {/* <div className="col-md-4 edit-button">
                                                <button className="btn btn-link profile-edit">Edit</button>
                                            </div> */}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  rounded bg-white profile-top-margin ${
                      activeTab === "reviews" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-11">
                        <h2 className="font-italic fw-bold">Security</h2>
                        <p className="light-font-weight">
                          Change your security settings, set up secure
                          authentication or delete your account.
                        </p>
                      </div>
                    </div>
                    <p></p>
                    <div className="row personal-info__item personal-details-btop">
                      <div className="col-md-2">
                        <p className="">Password</p>
                      </div>
                      <div className="col-md-6">
                        <p className="">Change your password</p>
                      </div>
                      <div className="col-md-4 edit-button">
                        <a href="/change-password">
                          <button className="btn btn-link profile-edit">
                            Change
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
