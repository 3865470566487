import { Form } from "reactstrap";
import React, { useState, useEffect, createRef } from "react";
import { getLeaseUserToken } from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import AutoComplete from "react-google-autocomplete";
import ReactFlagsSelect from "react-flags-select";
import { useCookies } from "react-cookie";
import "./lease-search-console.css";
import Swal from "sweetalert2";
import axios from "axios";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

const LeaseSearchConsole = () => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleTypesFlag, setVehicleTypesFlag] = useState(false);

  const [cookies] = useCookies(["countryiso"]);

  const searchInput = createRef(null);
  const [address, setAddress] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [leaseUserid, setLeaseUserid] = useState(0);
  const [select, setSelect] = useState(
    cookies.countryiso === undefined ? "" : cookies.countryiso
  );

  const onSelect = (code) => setSelect(code);
  const countryIso2 = [
    "AF",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BQ",
    "BA",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "CV",
    "KH",
    "CM",
    "CA",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "CI",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "SZ",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MK",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
  ];

  const handleFlagSelect = (countryCode) => {
    setSelect(countryCode);

    const countryISO2Map = {
      AF: "af", // Afghanistan
      AX: "ax", // Åland Islands
      AL: "al", // Albania
      DZ: "dz", // Algeria
      AS: "as", // American Samoa
      AD: "ad", // Andorra
      AO: "ao", // Angola
      AI: "ai", // Anguilla
      AQ: "aq", // Antarctica
      AG: "ag", // Antigua and Barbuda
      AR: "ar", // Argentina
      AM: "am", // Armenia
      AW: "aw", // Aruba
      AU: "au", // Australia
      AT: "at", // Austria
      AZ: "az", // Azerbaijan
      BS: "bs", // Bahamas
      BH: "bh", // Bahrain
      BD: "bd", // Bangladesh
      BB: "bb", // Barbados
      BY: "by", // Belarus
      BE: "be", // Belgium
      BZ: "bz", // Belize
      BJ: "bj", // Benin
      BM: "bm", // Bermuda
      BT: "bt", // Bhutan
      BO: "bo", // Bolivia
      BQ: "bq", // Bonaire, Sint Eustatius and Saba
      BA: "ba", // Bosnia and Herzegovina
      BW: "bw", // Botswana
      BV: "bv", // Bouvet Island
      BR: "br", // Brazil
      IO: "io", // British Indian Ocean Territory
      BN: "bn", // Brunei Darussalam
      BG: "bg", // Bulgaria
      BF: "bf", // Burkina Faso
      BI: "bi", // Burundi
      CV: "cv", // Cabo Verde
      KH: "kh", // Cambodia
      CM: "cm", // Cameroon
      CA: "ca", // Canada
      KY: "ky", // Cayman Islands
      CF: "cf", // Central African Republic
      TD: "td", // Chad
      CL: "cl", // Chile
      CN: "cn", // China
      CX: "cx", // Christmas Island
      CC: "cc", // Cocos (Keeling) Islands
      CO: "co", // Colombia
      KM: "km", // Comoros
      CG: "cg", // Congo
      CD: "cd", // Democratic Republic of the Congo
      CK: "ck", // Cook Islands
      CR: "cr", // Costa Rica
      HR: "hr", // Croatia
      CU: "cu", // Cuba
      CW: "cw", // Curaçao
      CY: "cy", // Cyprus
      CZ: "cz", // Czech Republic
      CI: "ci", // Ivory Coast
      DK: "dk", // Denmark
      DJ: "dj", // Djibouti
      DM: "dm", // Dominica
      DO: "do", // Dominican Republic
      EC: "ec", // Ecuador
      EG: "eg", // Egypt
      SV: "sv", // El Salvador
      GQ: "gq", // Equatorial Guinea
      ER: "er", // Eritrea
      EE: "ee", // Estonia
      SZ: "sz", // Eswatini
      ET: "et", // Ethiopia
      FK: "fk", // Falkland Islands
      FO: "fo", // Faroe Islands
      FJ: "fj", // Fiji
      FI: "fi", // Finland
      FR: "fr", // France
      GF: "gf", // French Guiana
      PF: "pf", // French Polynesia
      TF: "tf", // French Southern Territories
      GA: "ga", // Gabon
      GM: "gm", // Gambia
      GE: "ge", // Georgia
      DE: "de", // Germany
      GH: "gh", // Ghana
      GI: "gi", // Gibraltar
      GR: "gr", // Greece
      GL: "gl", // Greenland
      GD: "gd", // Grenada
      GP: "gp", // Guadeloupe
      GU: "gu", // Guam
      GT: "gt", // Guatemala
      GG: "gg", // Guernsey
      GN: "gn", // Guinea
      GW: "gw", // Guinea-Bissau
      GY: "gy", // Guyana
      HT: "ht", // Haiti
      HM: "hm", // Heard Island and McDonald Islands
      VA: "va", // Holy See
      HN: "hn", // Honduras
      HK: "hk", // Hong Kong
      HU: "hu", // Hungary
      IS: "is", // Iceland
      IN: "in", // India
      ID: "id", // Indonesia
      IR: "ir", // Iran
      IQ: "iq", // Iraq
      IE: "ie", // Ireland
      IM: "im", // Isle of Man
      IL: "il", // Israel
      IT: "it", // Italy
      JM: "jm", // Jamaica
      JP: "jp", // Japan
      JE: "je", // Jersey
      JO: "jo", // Jordan
      KZ: "kz", // Kazakhstan
      KE: "ke", // Kenya
      KI: "ki", // Kiribati
      KP: "kp", // North Korea
      KR: "kr", // South Korea
      KW: "kw", // Kuwait
      KG: "kg", // Kyrgyzstan
      LA: "la", // Laos
      LV: "lv", // Latvia
      LB: "lb", // Lebanon
      LS: "ls", // Lesotho
      LR: "lr", // Liberia
      LY: "ly", // Libya
      LI: "li", // Liechtenstein
      LT: "lt", // Lithuania
      LU: "lu", // Luxembourg
      MO: "mo", // Macao
      MG: "mg", // Madagascar
      MW: "mw", // Malawi
      MY: "my", // Malaysia
      MV: "mv", // Maldives
      ML: "ml", // Mali
      MT: "mt", // Malta
      MH: "mh", // Marshall Islands
      MQ: "mq", // Martinique
      MR: "mr", // Mauritania
      MU: "mu", // Mauritius
      YT: "yt", // Mayotte
      MX: "mx", // Mexico
      FM: "fm", // Micronesia
      MD: "md", // Moldova
      MC: "mc", // Monaco
      MN: "mn", // Mongolia
      ME: "me", // Montenegro
      MS: "ms", // Montserrat
      MA: "ma", // Morocco
      MZ: "mz", // Mozambique
      MM: "mm", // Myanmar
      NA: "na", // Namibia
      NR: "nr", // Nauru
      NP: "np", // Nepal
      NL: "nl", // Netherlands
      NC: "nc", // New Caledonia
      NZ: "nz", // New Zealand
      NI: "ni", // Nicaragua
      NE: "ne", // Niger
      NG: "ng", // Nigeria
      NU: "nu", // Niue
      NF: "nf", // Norfolk Island
      MK: "mk", // North Macedonia
      MP: "mp", // Northern Mariana Islands
      NO: "no", // Norway
      OM: "om", // Oman
      PK: "pk", // Pakistan
      PW: "pw", // Palau
      PS: "ps", // Palestine
      PA: "pa", // Panama
      PG: "pg", // Papua New Guinea
      PY: "py", // Paraguay
      PE: "pe", // Peru
      PH: "ph", // Philippines
      PN: "pn", // Pitcairn
      PL: "pl", // Poland
      PT: "pt", // Portugal
      PR: "pr", // Puerto Rico
      QA: "qa", // Qatar
      RE: "re", // Réunion
      RO: "ro", // Romania
      RU: "ru", // Russia
      RW: "rw", // Rwanda
      BL: "bl", // Saint Barthélemy
      SH: "sh", // Saint Helena
      KN: "kn", // Saint Kitts and Nevis
      LC: "lc", // Saint Lucia
      MF: "mf", // Saint Martin
      PM: "pm", // Saint Pierre and Miquelon
      VC: "vc", // Saint Vincent and the Grenadines
      WS: "ws", // Samoa
      SM: "sm", // San Marino
      ST: "st", // São Tomé and Príncipe
      SA: "sa", // Saudi Arabia
      SN: "sn", // Senegal
      RS: "rs", // Serbia
      SC: "sc", // Seychelles
      SL: "sl", // Sierra Leone
      SG: "sg", // Singapore
      SX: "sx", // Sint Maarten
      SK: "sk", // Slovakia
      SI: "si", // Slovenia
      SB: "sb", // Solomon Islands
      SO: "so", // Somalia
      ZA: "za", // South Africa
      GS: "gs", // South Georgia
      SS: "ss", // South Sudan
      ES: "es", // Spain
      LK: "lk", // Sri Lanka
      SD: "sd", // Sudan
      SR: "sr", // Suriname
      SJ: "sj", // Svalbard and Jan Mayen
      SE: "se", // Sweden
      CH: "ch", // Switzerland
      SY: "sy", // Syria
      TW: "tw", // Taiwan
      TJ: "tj", // Tajikistan
      TZ: "tz", // Tanzania
      TH: "th", // Thailand
      TL: "tl", // Timor-Leste
      TG: "tg", // Togo
      TK: "tk", // Tokelau
      TO: "to", // Tonga
      TT: "tt", // Trinidad and Tobago
      TN: "tn", // Tunisia
      TR: "tr", // Turkey
      TM: "tm", // Turkmenistan
      TC: "tc", // Turks and Caicos Islands
      TV: "tv", // Tuvalu
      UG: "ug", // Uganda
      UA: "ua", // Ukraine
      AE: "ae", // United Arab Emirates
      GB: "gb", // United Kingdom
      US: "us", // United States
      UM: "um", // United States Minor Outlying Islands
      UY: "uy", // Uruguay
      UZ: "uz", // Uzbekistan
      VU: "vu", // Vanuatu
      VE: "ve", // Venezuela
      VN: "vn", // Vietnam
      VG: "vg", // Virgin Islands (British)
      VI: "vi", // Virgin Islands (U.S.)
      WF: "wf", // Wallis and Futuna
      EH: "eh", // Western Sahara
      YE: "ye", // Yemen
      ZM: "zm", // Zambia
      ZW: "zw", // Zimbabwe
    };

    const iso2Code = countryISO2Map[countryCode];
    const redirectURL = iso2Code ? `#` : "#";

    window.location.href = redirectURL;
  };

  useEffect(() => {
    const userLoggedData = getLeaseUserToken();

    if (userLoggedData.lease_user_id !== null) {
      setLeaseUserid(userLoggedData.lease_user_id);
    }
  }, []);

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);

    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );

    // Add event listener for input changes
    searchInput.current.addEventListener("input", handleInput);

    // Handle initial suggestions
    handleInput();
  };

  // Handle input changes and fetch suggestions
  const handleInput = () => {
    if (!searchInput.current) return;

    const input = searchInput.current.value;

    // Use AutocompleteService to fetch predictions
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input,
        types: ["(regions)"], // Restrict to regions (cities and countries)
        componentRestrictions: { country: "US" }, // Replace with your desired country code
      },
      (predictions) => {
        const transformedSuggestions = predictions.map((prediction) => {
          const { description, structured_formatting } = prediction;
          const { main_text, secondary_text } = structured_formatting;
          const [placeName, cityName, countryName] = [
            main_text,
            secondary_text,
            description,
          ];
          return {
            placeName,
            cityName,
            countryName,
            description,
          };
        });
        setSuggestions(transformedSuggestions);
      }
    );
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  // Load the Vehicle type Dropdown

  useEffect(() => {
    axios
      .get("https://backend.carsinafrica.com/frontend/getVehicleCategories")
      .then((response) => {
        setVehicleTypes(response.data.data);
        setVehicleTypesFlag(true);
      });
  }, []);

  {
    /* START - Search Console */
  }

  {
    /* START - Pickup Use-State */
  }
  const [paddress, setPAddress] = useState("");
  const [pcity, setPCity] = useState("");
  const [pstate, setPState] = useState("");
  const [pcountry, setPCountry] = useState("");
  const [pcountryIso, setPCountryIso] = useState("");
  const [pcoordinates, setPCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [selectedVehicleAgeType, setSelectedVehicleAgeType] = useState("new");
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState("long");
  const [selectedYear, setSelectedYear] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(0);

  {
    /* END - Search Console */
  }

  const handlePickupSelection = (place) => {
    // Extract details from the place object
    const city =
      place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name || "null";
    const address = place.formatted_address || "null";
    const country =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.long_name || "null";
    const state =
      place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name || "null";
    const countryIso =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.short_name || "null";
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    setPAddress(address);
    setPCoordinates({ lat: latLng.lat, lng: latLng.lng });
    setPCity(city);
    setPState(state);
    setPCountry(country);
    setPCountryIso(countryIso);
  };

  const handleVehicleAgeTypeChange = (event) => {
    setSelectedVehicleAgeType(event.target.value);
  };

  const handleVehicleTypeChange = (event) => {
    const selectedId = Number(event.target.value);
    const selectedVehicle = vehicleTypes.find(
      (vehicle) => vehicle.id === selectedId
    );
    setSelectedVehicleType(selectedVehicle);
  };

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
    if (event.target.value === "short") {
      setSelectedYear(0);
      setSelectedMonth(3);
    } else {
      setSelectedYear(1);
      setSelectedMonth(0);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleFindCar = () => {
    if (paddress === "") {
      Swal.fire("Please Select Pickup Address");
    } else if (selectedVehicleType === null) {
      Swal.fire("Please Select Vehicle Type");
    } else if (
      leaseUserid === 0 ||
      leaseUserid === null ||
      leaseUserid === undefined
    ) {
      Swal.fire({
        title: "Please Login/Register",
        showDenyButton: true,
        confirmButtonText: "Login",
        denyButtonText: `Register`,
      }).then((result) => {
        if (result.isConfirmed) {
          const url = `/lease/lease-booking/${selectedTerm}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${selectedVehicleAgeType}/${selectedVehicleType.id}/${selectedVehicleType.vehicletype}/${leaseUserid}/${selectedMonth}/${selectedYear}/lease-user-login`;
          window.location.href = url;
        } else if (result.isDenied) {
          const url = "/lease-user-registration";
          window.location.href = url;
        }
      });
    } else {
      const url = `/lease/lease-booking/${selectedTerm}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${selectedVehicleAgeType}/${selectedVehicleType.id}/${selectedVehicleType.vehicletype}/${leaseUserid}/${selectedMonth}/${selectedYear}`;
      window.location.href = url;
    }
  };

  return (
    <>
      <div className="background-container">
        <div className="search-form-container">
          <h1 className="fw-bold text-center pb-2 text-white">
            Find Your Lease Car
          </h1>
          <hr style={{ border: "1px solid white" }} />
          <Form className="search-form">
            <ReactFlagsSelect
              id="lease-location-selector"
              selected={select}
              onSelect={handleFlagSelect}
              countries={countryIso2}
              searchable
              className="field-height custom-flags-select"
            />
            <AutoComplete
              className="autocomplete-input field-height lease-field-size lease-pickup-field"
              placeholder="Pickup Location"
              options={{
                types: ["(cities)"],
                componentRestrictions: {
                  country: select,
                },
              }}
              apiKey={apiKey}
              onPlaceSelected={(place) => handlePickupSelection(place)}
            />
            <select
              className="form-select vehicle-type-dropdown dropdown field-height drop-down-fieldsize margin-left margin-right"
              value={selectedVehicleAgeType}
              onChange={handleVehicleAgeTypeChange}
            >
              <option disabled>Select Vehicle Age Type</option>
              <option value="old">OLD</option>
              <option value="new">NEW</option>
            </select>
            <select
              className="form-select vehicle-type-dropdown dropdown field-height drop-down-fieldsize margin-left margin-right"
              value={selectedVehicleType?.id || ""}
              onChange={handleVehicleTypeChange}
            >
              <option value="" disabled>
                Vehicle Type
              </option>
              {vehicleTypes.map((vehicleType) => (
                <option key={vehicleType.id} value={vehicleType.id}>
                  {vehicleType.vehicletype}
                </option>
              ))}
            </select>
            <select
              className="form-select term-dropdown dropdown field-height drop-down-fieldsize margin-left margin-right"
              value={selectedTerm}
              onChange={handleTermChange}
            >
              <option disabled>Select Term</option>
              <option value="long">Long Term</option>
              <option value="short">Short Term</option>
            </select>
            {selectedTerm === "short" ? (
              <select
                className="form-select month-dropdown dropdown field-height drop-down-fieldsize margin-left margin-right"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option disabled>Select Months</option>
                <option value="3">3 months</option>
                <option value="6">6 months</option>
                <option value="9">9 months</option>
                <option value="12">12 months</option>
              </select>
            ) : (
              <>
                <select
                  className="form-select year-month-dropdown dropdown field-height drop-down-fieldsize year-height"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <option disabled>Select Years</option>
                  <option value="1">1 year</option>
                  <option value="2">2 years</option>
                  <option value="3">3 years</option>
                </select>
                <select
                  className="form-select month-year-dropdown dropdown field-height drop-down-fieldsize margin-left margin-right"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option disabled>Select Months</option>
                  <option value="0">0 month</option>
                  <option value="3">3 months</option>
                  <option value="6">6 months</option>
                  <option value="9">9 months</option>
                  <option value="12">12 months</option>
                </select>
              </>
            )}
            <button
              type="button"
              id="find-car-1"
              className="find-car-button field-height margin-right"
              onClick={handleFindCar}
            >
              <i className="fas fa-search"></i>&nbsp;Find Car
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};
export default LeaseSearchConsole;
