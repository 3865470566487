import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import { useState, useLayoutEffect } from "react";
import {
  getToken,
  getLeaseUserToken,
  removeToken,
  removeLeaseUserToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";

// Header Menus -> STARTED
import Homepage from "../../pages/Homepage/Homepage.js";
import AboutUs from "../../pages/AboutUs/AboutUs.js";
import Services from "../../pages/Services/Services.js";
import ContactUs from "../../pages/ContactUs/ContactUs.js";
import UserLogin from "../../pages/Login/Login.js";
import Registration from "../../pages/Registration/Registration.js";

// Header Menus -> ENDED
import NotFound from "../../pages/NotFound/NotFound.jsx";
import DataPolicy from "../../pages/DataPolicy/DataPolicy.js";
import Careers from "../../pages/Careers/Careers.js";
import CancellationPolicy from "../../pages/CancellationPolicy/CancellationPolicy.js";
import TermsandConditions from "../../pages/TermsandConditions/TermsandConditions.js";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy.js";
import Faqs from "../../pages/Faqs/Faqs.js";
import PartnerLogin from "../../pages/PartnerLogin/PartnerLogin.js";
import CorporateLogin from "../../pages/CorporateLogin/Corporate.js";
import ForgetPassword from "../../pages/ForgetPassword/ForgotPassword.js";
import LeaseForgotPasswordComp from "../../pages/ForgetPassword/LeaseForgotPassword.js";
import GlobalPresence from "../../pages/GlobalPresence/GlobalPresence.js";
import CountryLocation from "../../pages/GlobalPresence/CountryLocation.js";
import Dashboard from "../../pages/Dashboard/Dashboard.js";
import CarBooking from "../../pages/Carbooking/Carbooking.js";
import Login from "../../pages/Login/Login.js";
import UserProfile from "../../pages/Dashboard/Profile/Profile.js";
import ChangePassword from "../../pages/ChangePassword/ChangePassword.js";
import LeaseChangePassword from "../../pages/ChangePassword/LeaseChangePassword.js";
import Vehicles from "../../pages/Carlist/Carlist.js";
import Enquiry from "../../pages/Enquiry/Enquiry.js";
import TrackBooking from "../../pages/Trackbooking/TrackBooking.js";
import Lease from "../../pages/LeaseBooking/Lease.js";
import LeaseBooking from "../../pages/LeaseBooking/LeaseBooking.js";
import LeaseLogin from "../../pages/Login/LeaseLogin.js";
import LeaseRegistration from "../../pages/Registration/LeaseRegistration.js";
export default function App() {
  const [userIsLogged, setUserIsLogged] = useState(true);
  const [leaseUserIsLogged, setLeaseUserIsLogged] = useState(true);
  const userId = localStorage.getItem("user_id");
  const leaseUserId = localStorage.getItem("lease_user_id");
  const userLoggedData = getToken();
  const LeaseUserLoggedData = getLeaseUserToken();

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-status?id=${userId}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          setUserIsLogged(true);
        } else {
          setUserIsLogged(false);
          removeToken(userLoggedData);
        }
      } catch (error) {
        setUserIsLogged(false);
      }
    };
    fetchData();
  }, [userIsLogged]);

  useLayoutEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-status?id=${leaseUserId}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          setLeaseUserIsLogged(true);
        } else {
          setLeaseUserIsLogged(false);
          removeLeaseUserToken(LeaseUserLoggedData);
        }
      } catch (error) {
        setLeaseUserIsLogged(false);
      }
    };
    fetchUserData();
  }, [leaseUserIsLogged]);

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="*" element={<NotFound />} />
          {/* Header Menus Routing -> STARTED*/}
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/lease" element={<Lease />} />
          <Route exact path="about-us" element={<AboutUs />} />
          <Route exact path="our-services" element={<Services />} />
          <Route exact path="contact-us" element={<ContactUs />} />
          {/* Header Menus Routing -> ENDED*/}
          <Route exact path="data-policy" element={<DataPolicy />} />
          <Route exact path="careers" element={<Careers />} />
          <Route
            exact
            path="cancellation-policy"
            element={<CancellationPolicy />}
          />
          <Route exact path="enquiry" element={<Enquiry />} />
          <Route
            exact
            path="terms-and-conditions"
            element={<TermsandConditions />}
          />
          <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="faqs" element={<Faqs />} />
          <Route
            exact
            path="user-login"
            element={userIsLogged ? <Navigate to="/" /> : <UserLogin />}
          />
          <Route exact path="lease-user-login" element={<LeaseLogin />} />
          <Route
            exact
            path="lease-user-registration"
            element={<LeaseRegistration />}
          />
          <Route exact path="user-registration" element={<Registration />} />
          <Route exact path="partner-login" element={<PartnerLogin />} />
          <Route exact path="corporate-login" element={<CorporateLogin />} />
          <Route exact path="forgot-password" element={<ForgetPassword />} />
          <Route
            exact
            path="lease-forgot-password"
            element={<LeaseForgotPasswordComp />}
          />
          <Route exact path="global-presence" element={<GlobalPresence />} />
          <Route
            exact
            path="global-presence/:countryIso/:countryName"
            element={<CountryLocation />}
          />
          <Route
            exact
            path="dashboard"
            element={!userIsLogged ? <Navigate to="/" /> : <Dashboard />}
          />
          <Route
            exact
            path="profile"
            element={!userIsLogged ? <Navigate to="/" /> : <UserProfile />}
          />
          <Route
            exact
            path="change-password"
            element={!userIsLogged ? <Navigate to="/" /> : <ChangePassword />}
          />
          <Route
            exact
            path="lease-change-password"
            element={
              !leaseUserIsLogged ? (
                <Navigate to="/lease" />
              ) : (
                <LeaseChangePassword />
              )
            }
          />
          <Route exact path="vehicles" element={<Vehicles />} />
          <Route exact path="track-booking" element={<TrackBooking />} />

          {/* START -> CIA CAR LISTING ROUTE*/}
          {/* START -> CIA LOCAL SERVICE */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:pdate/:ptime/:ddate/:dtime/:userid"
            element={<Vehicles />}
          />
          {/* Local Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Local Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId/user-login"
            element={<Login />}
          />
          {/* Out Station Vehicle Search List */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime"
            element={<Vehicles />}
          />
          {/* Out Station Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Out Station Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId/user-login"
            element={<Login />}
          />
          {/* Transfer Vehicle Search List */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime"
            element={<Vehicles />}
          />
          {/* Transfer Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Transfer Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId/user-login"
            element={<Login />}
          />
          {/* Self Drive Vehicle Search List */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:pdate/:ptime/:ddate/:dtime"
            element={<Vehicles />}
          />
          {/* Self Drive Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Self Drive Vehicle Detail & Booking Page Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId/user-login"
            element={<Login />}
          />
          {/* Self Drive Vehicle Search List */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime"
            element={<Vehicles />}
          />
          {/* Self Drive Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Self Drive Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:vehicleid/:searchId/user-login"
            element={<Login />}
          />
          {/* END -> CIA CAR LISTING ROUTE*/}

          {/* START -> Lease Vehicle Detail & Booking Page */}
          <Route
            exact
            path="lease/lease-booking/:term/:pAddress/:pCity/:pState/:pCountry/:pCountryIso/:pLatitude/:pLongitude/:vehicleAgeType/:vehicleTypeId/:vehicleTypeName/:userId/:months/:years"
            element={
              !leaseUserIsLogged ? <Navigate to="/lease" /> : <LeaseBooking />
            }
          />
          <Route
            exact
            path="lease/lease-booking/:term/:pAddress/:pCity/:pState/:pCountry/:pCountryIso/:pLatitude/:pLongitude/:vehicleAgeType/:vehicleTypeId/:vehicleTypeName/:userId/:months/:years/lease-user-login"
            element={<LeaseLogin />}
          />
          {/* END -> Lease Vehicle Detail & Booking Page */}

          {/* START -> SAVAARI CAR LISTING ROUTE*/}
          {/* Local Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Local Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid/user-login"
            element={<Login />}
          />
          {/* Out Station Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Out Station Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid/user-login"
            element={<Login />}
          />
          {/* Transfer Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Transfer Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:svri/:carid/user-login"
            element={<Login />}
          />
          {/* Self Drive Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:svri/:carid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Self Drive Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/:svri/:carid/user-login"
            element={<Login />}
          />
          {/* END -> SAVAARI CAR LISTING ROUTE*/}

          {/* START -> IWAY CAR LISTING ROUTE*/}
          {/* Local Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Local Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid/user-login"
            element={<Login />}
          />
          {/* Out Station Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Out Station Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid/user-login"
            element={<Login />}
          />
          {/* Transfer Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Transfer Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:subservice/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid/user-login"
            element={<Login />}
          />
          {/* Self Drive Vehicle Detail & Booking Page */}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/iway/:iway/:priceid"
            element={!userIsLogged ? <Navigate to="/" /> : <CarBooking />}
          />
          {/* Self Drive Vehicle Search List Login*/}
          <Route
            exact
            path="/vehicles/:service/:paddress/:pcity/:pstate/:pcountry/:pcountryiso/:platcoordinates/:plngcoordinates/:userid/:daddress/:dcity/:dstate/:dcountry/:dcountryiso/:dlatcoordinates/:dlngcoordinates/:pdate/:ptime/:ddate/:dtime/vehicle-booking/:vehicletid/iway/:iway/:priceid/user-login"
            element={<Login />}
          />
          {/* END -> IWAY CAR LISTING ROUTE*/}
        </Routes>
      </Router>
    </>
  );
}
