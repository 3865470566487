/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import "./ContactUs.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { useCookies } from "react-cookie";

export default function ContactUs() {
  const [cookies, setCookie] = useCookies([
    "city",
    "state",
    "country",
    "countryiso",
  ]);
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [primaryContactEmail, setprimaryContactEmail] = useState();
  const [secondaryContactEmail, setsecondaryContactEmail] = useState();
  const [primaryContactNumber, setprimaryContactNumber] = useState();
  const [secondaryContactNumber, setsecondaryContactNumber] = useState();
  const [contactAddress, setcontactAddress] = useState();
  const encodedCountry = encodeURIComponent(cookies.country || null);
  const encodedCity = encodeURIComponent(cookies.city || null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState(213);
  const [loading, setLoading] = useState(false);

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneNumberErrorFlag, setPhoneNumberErrorFlag] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (phoneNumberErrorFlag || !value) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Invalid Phone Number",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    if (name.length === 0) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Name Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (!emailRegex.test(email)) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Invalid Email Format",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (email.length === 0) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Email Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (value.length === 0 || error !== "") {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Please Check Your Phone Number",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (message.length === 0) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Message Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else {
      const url = "https://backend.carsinafrica.com/frontend/inquiry";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("contact_number", value);
      fData.append("message", message);

      axios
        .post(url, fData)
        .then((response) => {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "Thank You For Contacting Us, Our Team Will Connect Your Shortly!",
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            window.location.href = "/";
          });
        })
        .catch((error) => alert(error));
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  function getContactDetails() {
    axios
      .get(
        `https://backend.carsinafrica.com/onlineApi/search_address_pno.php?city=${encodedCity}&country=${encodedCountry}`
      )
      .then(function (response) {
        setprimaryContactEmail(response.data[0].contact_email_primary);
        setsecondaryContactEmail(response.data[0].contact_email_secondary);
        setprimaryContactNumber(response.data[0].contact_number_primary);
        setsecondaryContactNumber(response.data[0].contact_number_secondary);
        setcontactAddress(response.data[0].address);
      });
  }

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("contact-us");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  const [value, setValue] = useState(0);
  const [error, setError] = useState("");

  const handlePhoneNumberChange = (value) => {
    setValue(value);

    // Reset the error message if user is editing and within the valid range
    if (value === undefined) {
      setPhoneNumberError("Invalid phone number.");
      setPhoneNumberErrorFlag(true);
    } else if (value.length >= 12 && value.length <= 15) {
      setPhoneNumberError(""); // Reset error if within range
      setPhoneNumberErrorFlag(false);
    }
  };

  const handleBlur = () => {
    const phone = value;

    if (phone === undefined) {
      setPhoneNumberError("Invalid phone number.");
      setPhoneNumberErrorFlag(true);
      return;
    } else if (phone.length < 12 || phone.length > 15) {
      // Check for minimum and maximum length
      setPhoneNumberError("Invalid phone number.");
      setPhoneNumberErrorFlag(true);
      return;
    }

    // If valid, trim the phone number to 15 characters max
    if (phone.length > 15) {
      setValue(value);
      setPhoneNumberErrorFlag(false);
    }

    setPhoneNumberError(""); // Clear any existing error if everything is valid
  };

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/white-coupe-sport-car-parking-bridge-contact-us.jpg"
          }
          className="img-fluid"
          alt="contact"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-contact-us-content-center align-items-center text-center">
            <div className="col-md-12">
              <h1 className="banner-caption text-shadow text-white">
                Contact Us
              </h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      {loading ? (
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
            />
          </div>
        </div>
      ) : (
        <section className="contact box-shadow" id="contact">
          <div className="container">
            <div className="heading text-center">
              <h2>
                Contact
                <span> Us </span>
              </h2>
              <p className="text-black">
                We'd love to hear from you! If you have any questions, feedback,
                or inquiries, please don't hesitate to get in touch with us.{" "}
                <br />
                Our dedicated team is here to assist you.
              </p>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="title">
                  <h3>Contact details</h3>
                  <p className="text-black">
                    We'd love to hear from you! If you have any questions,
                    feedback, or inquiries, please don't hesitate to get in
                    touch with us.{" "}
                  </p>
                </div>
                <div className="contact-us-content">
                  {/* Info-1 */}
                  <div className="info">
                    <i className="fas fa-mobile-alt" />
                    <h4 className="d-inline-block text-black">
                      PHONE :
                      <br />
                      <span className="text-black fw-semi-bold">
                        {primaryContactNumber}, {secondaryContactNumber}
                      </span>
                    </h4>
                  </div>
                  {/* Info-2 */}
                  <div className="info">
                    <i className="far fa-envelope" />
                    <h4 className="d-inline-block">
                      EMAIL :
                      <br />
                      <span className="text-black">{primaryContactEmail}</span>
                    </h4>
                  </div>
                  {/* Info-3 */}
                  <div className="info">
                    <i className="fas fa-map-marker-alt" />
                    <h4>
                      ADDRESS :<br />
                      <span className="text-black">{contactAddress}</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name *"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Your Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-12 mb-2">
                      <PhoneInput
                        defaultCountry="IN"
                        country="IN"
                        type="text"
                        id="mobile-number"
                        name="phoneNumber"
                        className="phoneinputfield register-phone-number"
                        placeholder="Mobile Number"
                        value={value}
                        onChange={handlePhoneNumberChange}
                        onBlur={handleBlur}
                        style={{ height: "54px" }}
                        required
                      />
                      {phoneNumberError && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {phoneNumberError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={5}
                      // id="message"
                      name="message"
                      placeholder="Message *"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      defaultValue={""}
                      required
                    />
                  </div>
                  <button className="partner-corporate-bg contact-us-ssumit-btn" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
}
