import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import axios from "axios";
import {
  useLoginUserMutation,
  useLoginUserBBMutation,
  useLoginUserCCMutation,
} from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import Swal from "sweetalert2";
import Topbar from "../../components/Topbar/Topbar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./ForgetPassword.css";
export default function ForgotPassword() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=forgot-password&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();

  const [userLoginCredits, setUserLoginCredits] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserLoginCredits((values) => ({ ...values, [name]: value }));
  };

  const handleUserLogin = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    axios
      .post(
        "https://backend.carsinafrica.com/frontend/resetpassword",
        userLoginCredits
      )
      .then((response) => {
        // Handle the response from the API
        setIsLoading(false);
        if (response.data.status === "Success") {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            window.location.href = `/`;
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          });
        }
      })
      .catch((error) => {
        // Handle errors that occurred during the request
        console.error("Error:", error);
      });
  };

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="container pt-5 mt-4 mb-5">
          <div className="custom-container-gif">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/loader.gif"}
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div className="container p-5">
          <div className="row">
            <section className="signup">
              <div className="sign_up">
                <div className="signup-content">
                  <div className="signup-form">
                    <h2 className="form-title fw-bold">Forgot Password</h2>
                    <p className="font-weight-semibold">
                      Your password will be shared with you on your registered
                      email.
                      <br />
                    </p>
                    <form
                      className="register-form"
                      id="register-form"
                      onSubmit={handleUserLogin}
                    >
                      <div className="form-group">
                        <Input
                          type="email"
                          id="username"
                          name="email"
                          size="large"
                          placeholder="Enter Your Email ID"
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          className="custom-input"
                          onChange={handleUserLoginDetails}
                          required
                        />
                      </div>
                      <div className="form-group form-button">
                        <input
                          type="submit"
                          className="partner-corporate-bg"
                          value="Submit"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="signup-image">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/partner_login.jpg`}
                      alt="partner"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
