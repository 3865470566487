import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import axios from "axios";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import {
  getToken,
  storeToken,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import { useLoginUserMutation } from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import Swal from "sweetalert2";
import "./Login.css";
export default function LoginComp() {
  const {
    service,
    subservice,
    paddress,
    pcity,
    pstate,
    pcountry,
    pcountryiso,
    platcoordinates,
    plngcoordinates,
    daddress,
    dcity,
    dstate,
    dcountry,
    dcountryiso,
    dlatcoordinates,
    dlngcoordinates,
    pdate,
    ptime,
    ddate,
    dtime,
    vehicletid,
    vehicleid,
    vehiclecid,
    searchId,
    svri,
    iway,
    priceid,
    carid,
  } = useParams();
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();
  const [userid, setUserid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userIsLogged, setUserIsLogged] = useState(true);
  const uuserId = localStorage.getItem("user_id");
  const userLoggedData = getToken();

  useEffect(() => {
    if (userLoggedData.user_id != null) {
      setUserid(userLoggedData.user_id);
    }
  }, [userLoggedData.user_id]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-status?id=${uuserId}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          removeToken(userLoggedData);
        } else {
          setUserIsLogged(false);
          removeToken(userLoggedData);
        }
      } catch (error) {
        setUserIsLogged(false);
      }
    };
    fetchData();
  }, [userIsLogged]);

  const [userLoginCredits, setUserLoginCredits] = useState({});

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserLoginCredits((values) => ({ ...values, [name]: value }));
  };

  const handleUserLogin = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const res = await loginUser(userLoginCredits);

      if (res.data && res.data.success == 1) {
        // Store Token Code here
        storeToken(res.data.userinfo);

        if (svri === "svri") {
          if (service === "local") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${subservice}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(daddress)}/${encodeURIComponent(
                dcity
              )}/${encodeURIComponent(dstate)}/${encodeURIComponent(
                dcountry
              )}/${encodeURIComponent(dcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(dtime)}/vehicle-booking/${svri}/${carid}`;
            });
          } else if (service === "outstation") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(paddress)}/${encodeURIComponent(
                pcity
              )}/${encodeURIComponent(pstate)}/${encodeURIComponent(
                pcountry
              )}/${encodeURIComponent(pcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(dtime)}/vehicle-booking/${svri}/${carid}`;
            });
          } else if (service === "transfer") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${subservice}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(paddress)}/${encodeURIComponent(
                pcity
              )}/${encodeURIComponent(pstate)}/${encodeURIComponent(
                pcountry
              )}/${encodeURIComponent(pcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(dtime)}/vehicle-booking/${svri}/${carid}`;
            });
          }
        } else if (iway === "true" || iway === true) {
          if (service === "local") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${subservice}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(daddress)}/${encodeURIComponent(
                dcity
              )}/${encodeURIComponent(dstate)}/${encodeURIComponent(
                dcountry
              )}/${encodeURIComponent(dcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(
                dtime
              )}/vehicle-booking/iway/${iway}/${priceid}`;
            });
          }
        } else {
          if (vehicletid == null) {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/`;
            });
          } else if (service === "local") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${subservice}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(paddress)}/${encodeURIComponent(
                pcity
              )}/${encodeURIComponent(pstate)}/${encodeURIComponent(
                pcountry
              )}/${encodeURIComponent(pcountryiso)}/${encodeURIComponent(
                platcoordinates
              )}/${encodeURIComponent(plngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(
                dtime
              )}/vehicle-booking/${vehicletid}/${vehicleid}/${searchId}`;
            });
          } else if (service === "outstation") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(daddress)}/${encodeURIComponent(
                dcity
              )}/${encodeURIComponent(dstate)}/${encodeURIComponent(
                dcountry
              )}/${encodeURIComponent(dcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(
                dtime
              )}/vehicle-booking/${vehicletid}/${vehicleid}/${searchId}`;
            });
          } else if (service === "transfer") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${subservice}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(daddress)}/${encodeURIComponent(
                dcity
              )}/${encodeURIComponent(dstate)}/${encodeURIComponent(
                dcountry
              )}/${encodeURIComponent(dcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(
                ptime
              )}/vehicle-booking/${vehicletid}/${vehicleid}/${searchId}`;
            });
          } else if (service === "selfdrive") {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "You have successfully logged in.",
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/vehicles/${service}/${encodeURIComponent(
                paddress
              )}/${encodeURIComponent(pcity)}/${encodeURIComponent(
                pstate
              )}/${encodeURIComponent(pcountry)}/${encodeURIComponent(
                pcountryiso
              )}/${encodeURIComponent(platcoordinates)}/${encodeURIComponent(
                plngcoordinates
              )}/${userid}/${encodeURIComponent(daddress)}/${encodeURIComponent(
                dcity
              )}/${encodeURIComponent(dstate)}/${encodeURIComponent(
                dcountry
              )}/${encodeURIComponent(dcountryiso)}/${encodeURIComponent(
                dlatcoordinates
              )}/${encodeURIComponent(dlngcoordinates)}/${encodeURIComponent(
                pdate
              )}/${encodeURIComponent(ptime)}/${encodeURIComponent(
                ddate
              )}/${encodeURIComponent(
                dtime
              )}/vehicle-booking/${vehicletid}/${vehicleid}/${searchId}`;
            });
          }
        }
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          text: "Invalid Login Credentials",
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Login failed:", error.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
              alt="Loading..."
            />
          </div>
        </div>
      ) : (
        <div className="container full-width animate__animated animate__bounce">
          <section className="signup ">
            <div className="sign_up corporate">
              <div className="signup-content ">
                <div className="signup-form">
                  <h2 className="form-title fw-bold">Login</h2>
                  <p className="font-weight-semibold">
                    If you don't have an account,
                    <br />
                    You can{" "}
                    <Link
                      to="/user-registration"
                      className="font-weight-bold custom-text-color"
                      style={{ color: "#f57b20" }}
                    >
                      Register Here!
                    </Link>
                  </p>
                  <form
                    className="register-form"
                    id="register-form"
                    onSubmit={handleUserLogin}
                  >
                    <div className="form-group">
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        size="large"
                        placeholder="Enter Your Email ID"
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        className="custom-input w-100"
                        onChange={handleUserLoginDetails}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <Input.Password
                        type="password"
                        id="password"
                        name="password"
                        size="large"
                        className="custom-input w-100"
                        placeholder="Enter Your Password"
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={handleUserLoginDetails}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-9 col-lg-9">
                        <Link
                          to="/forgot-password"
                          className="font-weight-bold custom-text-color text-decoration-none"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>
                    <div className="form-group form-button pt-10">
                      <div className="button">
                        <input
                          className="reg-button"
                          type="submit"
                          name="send"
                          id="send"
                          value="Log In"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="signup-image">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/partner_login.jpg`}
                    alt="Example"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
